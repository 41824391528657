/*
 *** My Loyal Blue Subcription Home Page ***
 */

import KidsSubscriptionForm from "../components/KidsSubscriptionForm";
import styled from "styled-components";

const KidsClub = () => {
  return (
    <StyledHomeWrapper>
      <div className="container">
        <div className="intro-wrapper">
          <h1>
            Orlando Magic Kids Club{" "}
            <span>The Most Exclusive Club for Our Biggest Fans</span>
          </h1>
          <p className="subhealine">
            Your <b>FREE</b> membership includes:
          </p>
          <ul>
            <li>
              All NEW members will receive one free youth ticket to a single
              game with the purchase of an adult ticket
            </li>
            <li>
              10% Discounts for the Orlando Magic Team Shop all season long
            </li>
            <li>10% Discount on camp registrations</li>
            <li>Exclusive in-game experiences</li>
            <li>
              Member only branded giveaways (available for pick up in-arena and
              selected events)
            </li>
            <li>
              Monthly Kid’s Club eNews for the latest updates and upcoming
              events
            </li>
            <li>Exclusive invitations to Magic events throughout the season</li>
            <li>Monthly news, photos and videos just for kids</li>
          </ul>
          {/* <img
            className="main-image"
            src="https://cdn.nba.com/teams/uploads/sites/1610612753/2024/08/Magic-Phones-Subscription-Center.png"
            alt="Orlando Magic Notifications Screenshot"
          /> */}
        </div>
        <div className="form-wrapper">
          <KidsSubscriptionForm />
        </div>
      </div>
    </StyledHomeWrapper>
  );
};

export default KidsClub;

const StyledHomeWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #333
    url("https://cdn.nba.com/teams/uploads/sites/1610612753/2023/08/Team-Breakaway.jpg");
  background-size: cover;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(20, 20, 20, 0.7);
  }

  .container {
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .intro-wrapper {
    width: 60%;
    height: 100vh;
    padding: 20px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
    }
    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 32px;
        span {
          margin-top: 15px;
        }
      }
      span {
        display: block;
        font-size: 0.7em;
        font-weight: normal;
      }
    }
    p.subhealine {
      font-size: 18px;
      color: #fff;
      &:after {
        content: "";
        width: 100px;
        height: 6px;
        background: #0077c0;
        display: block;
        margin-top: 10px;
      }
    }
    ul {
      padding: 0 25px;
      li {
        color: #fff;
        font-size: 18px;
        margin: 10px 0;
      }
    }
    .main-image {
      max-width: 100%;
      @media (max-width: 768px) {
        margin-bottom: -25%;
        max-width: 100%;
      }
    }
  }

  .form-wrapper {
    background: #fff;
    width: 100%;
    height: 100vh;
    max-width: 40%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-shadow: -15px 0 40px rgba(0, 0, 0, 0.5);
    overflow: auto;
    @media (max-width: 768px) {
      height: auto;
      max-width: 100%;
    }
  }
`;
