import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { postUserData } from "../actions/userDataAction";
import { useHistory, useLocation } from "react-router-dom";
import { maskNumbers } from "../utils";

//Material UI
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import Icon from "@mui/material/Icon";

import {
  CustomCheckbox,
  CustomTextField,
} from "../styles/MaterialCustomStyles";

//Funtion to search for querys using Router
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const KidsSubscriptionForm = () => {
  //Get querys from URL
  let query = useQuery();
  const SOURCE = query.get("source");
  const EVENT = query.get("event");

  //Subscription form state
  const [userData, setUserData] = useState({
    EMAIL_ADDRESS: "",
    POSTAL_CODE: "",
    PARENT_FIRST_NAME: "",
    PARENT_LAST_NAME: "",
    PHONE: "",
    SOURCE,
    EVENT,
    ENEWS: false,
    TICKET_OFFERS: false,
    KIDSCLUB: true,
    ENEWS_FREQUENCY: "",
    SMS: false,
  });

  console.log(userData);

  //Get loading state from redux state
  const loading = useSelector((state) => state.user.loading);
  //Get subscriptionSuccessful state from redux state
  const success = useSelector((state) => state.user.subscriptionSuccessful);

  const dispatch = useDispatch();

  //When the form submit button is clicked
  const handleSubmit = (e) => {
    e.preventDefault();
    //This will disptach the action function with the user data inputted
    dispatch(postUserData(userData));
  };

  //Get history of the page from Router
  let history = useHistory();

  useEffect(() => {
    //Push url to /profile if success state is true
    if (success)
      history.push({
        pathname: "/profile",
        search: `?EMAIL_ADDRESS=${userData.EMAIL_ADDRESS}&new=true&kids=true`,
        state: userData,
      });
  });

  return (
    <StyledForm>
      <StyledHeadline>
        <img
          src="https://cdn.nba.com/teams/uploads/sites/1610612753/2023/08/kids_club-logo.png"
          alt="Orlando Magic Kids Club"
        />
      </StyledHeadline>

      <form onSubmit={handleSubmit}>
        <CustomTextField
          id="email_address"
          type="email"
          label="Email Address"
          name="email_address"
          fullWidth
          className="form-field"
          value={userData.EMAIL_ADDRESS}
          onChange={(e) =>
            setUserData({ ...userData, EMAIL_ADDRESS: e.target.value })
          }
          variant="filled"
          required
        />

        <TextField
          id="parent-first-name"
          type="text"
          label="Parent First Name"
          name="parent_first_name"
          fullWidth
          className="form-field"
          value={userData.PARENT_FIRST_NAME}
          onChange={(e) =>
            setUserData({ ...userData, PARENT_FIRST_NAME: e.target.value })
          }
          variant="filled"
          required
        />

        <TextField
          id="parent-last-name"
          type="text"
          label="Parent Last Name"
          name="parent_last_name"
          fullWidth
          className="form-field"
          value={userData.PARENT_LAST_NAME}
          onChange={(e) =>
            setUserData({ ...userData, PARENT_LAST_NAME: e.target.value })
          }
          variant="filled"
          required
        />

        <TextField
          id="postal_code"
          type="text"
          label="Postal Code"
          name="postal_code"
          fullWidth
          className="form-field"
          value={userData.POSTAL_CODE}
          onChange={(e) =>
            setUserData({
              ...userData,
              POSTAL_CODE: maskNumbers(e.target.value),
            })
          }
          variant="filled"
          required
        />

        <h2>Emails You May Like</h2>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="ticket-offers"
              name="ticket-offers"
              className="subscription-check"
              checked={!!userData.TICKET_OFFERS}
              onChange={(e) =>
                setUserData({ ...userData, TICKET_OFFERS: e.target.checked })
              }
            />
          }
          label="Ticket Offers"
        />
        <FormHelperText>
          Access to ticket presales, the best deals each month and exclusive
          discounts.
        </FormHelperText>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="enews"
              name="enews"
              className="subscription-check"
              checked={!!userData.ENEWS}
              onChange={(e) =>
                setUserData({ ...userData, ENEWS: e.target.checked })
              }
            />
          }
          label="Magic News"
        />
        <FormHelperText>
          Personalized updates about the Magic, trending topics, breaking news
          and more.
        </FormHelperText>

        <h2>Interested in Orlando Magic Tickets?</h2>
        <p>
          Enter your phone number below to receive a call from a ticket
          representative.
        </p>
        <TextField
          id="phone"
          type="text"
          label="Phone Number"
          name="phone"
          fullWidth
          className="form-field"
          value={userData.PHONE}
          onChange={(e) =>
            setUserData({ ...userData, PHONE: maskNumbers(e.target.value) })
          }
          variant="filled"
        />

        <Button
          className="submit"
          variant="contained"
          type="submit"
          color="primary"
          size="large"
          endIcon={<Icon>send</Icon>}
          disabled={loading && true}>
          Sign Me Up
          {loading && <CircularProgress className="progress" size={24} />}
        </Button>

        <a
          className="policy"
          href="https://www.nba.com/privacy-policy"
          target="_blank"
          rel="noreferrer">
          View our Privacy Policy
        </a>
      </form>
    </StyledForm>
  );
};

export default KidsSubscriptionForm;

//CSS Styles for this component

const StyledHeadline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-bottom: 15px;
  img {
    max-width: 250px;
    margin: 0 10px;
  }
  h1 {
    font-weight: 300;
    line-height: 1em;
    font-size: 30px;
    span {
      display: block;
      font-size: 0.75em;
    }
  }
`;

const StyledForm = styled.div`
  width: 100%;
  max-width: 330px;
  padding: 15px;
  position: relative;
  margin: auto 0;
  h2 {
    font-weight: normal;
    &:after {
      content: "";
      width: 100px;
      height: 6px;
      background: #0077c0;
      display: block;
      margin-top: 10px;
    }
  }

  button.submit {
    background: #0077c0;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #000;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  label {
    display: inline-block;
    margin-right: 5px;
  }
  input[type="checkbox"]:checked {
    color: black;
  }
  .subscription-check {
    font-weight: bold;
    padding: 0 8px;
  }
  .progress {
    position: absolute;
  }
  .form-field {
    margin-bottom: 20px;
    div &:after {
      display: none;
    }
  }
  .policy {
    display: block;
    text-align: center;
    color: #0077c0;
    text-decoration: none;
    margin: 25px 0 0;
  }

  //Material UI CSS Ovewrites
  .MuiFormControlLabel-label {
    vertical-align: middle;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
`;
